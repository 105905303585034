<template>
  <d-table
      :show-pagination="false"
      :tablefields="tableFields"
      :items="items"
      :is-busy="false"
  >

  </d-table>
</template>
<script>
import {getGroup} from "@api/doinsport/services/group/group.api";
import {getActivity} from "@api/doinsport/services/activity/activity.api";
import {getSubscriptionPlan} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {getClubClient} from "@api/doinsport/services/client/client.api";
import {fromIriReferenceToId} from "@/utils/form";

export default {
  data: () => ({
    items: [],
    selectedCustomers: [],
    selectedSubscriptions: [],
    selectedActivities: [],
    selectedGroups: [],
  }),
  props: {
    emailCampaign: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    tableFields() {
      return [
        {
          key: "iconLabel",
          iconClass: "icofont icofont-ui-message text-blue-darken",
          labelClass: "table-title-bold w-100",
          label: this.$t("views.marketing.report.resume-content.sent-list-table.title"),
          sortable: false
        },
        {
          key: "campaignSendingValue",
          label: "",
          class: 'text-left',
          sortable: false
        },
      ];
    },
    isPushCampaign() {
      return this.$route.name === 'campaign_push_report';
    }
  },
  created() {
    const requests = [];

    if (this.emailCampaign.filters) {
      for (const filter of this.emailCampaign.filters) {
        switch (filter.key) {
          case 'group':
            for (const groupId of filter.values) {
              requests.push(getGroup(groupId));
            }
            break;
          case 'activity':
            for (const activityId of filter.values) {
              requests.push(getActivity('/activities/' + activityId));
            }
            break;
          case 'subscriptionPlan':
            for (const subscriptionPlanId of filter.values) {
              requests.push(getSubscriptionPlan(subscriptionPlanId));
            }
            break;
        }
      }
    } else {
      for (const customerId of this.emailCampaign.clients) {
        requests.push(getClubClient(fromIriReferenceToId('/clubs/clients/', this.isPushCampaign ? customerId : customerId['@id'])));
      }
    }
    let promiseResponses = [];

    Promise.all(requests)
        .then(results => promiseResponses = results.map(result => result.data))
        .finally(()=> {
          for (const result of promiseResponses) {
            switch(result['@type']) {
              case 'ClubGroup':
                {
                  this.selectedGroups.push({code: result.id, label: result.name});
                  break;
                }
                case 'Activity':
                {
                  this.selectedActivities.push({code: result.id, label: result.name});
                  break;
                }
                case 'ClubSubscriptionPlan':
                {
                  this.selectedSubscriptions.push({code: result.id, label: result.name});
                  break;
                }
                case 'ClubClient':
                {
                  this.selectedCustomers.push({code: result.id, label: result.fullName});
                  break;
                }
            }
          }
          const filters = [
            {
              iconLabel: this.$t("views.marketing.report.resume-content.sent-list-table.filter-activity"),
              campaignSendingValue: {key: 'activities', values: this.selectedActivities}
            },
            {
              iconLabel: this.$t("views.marketing.report.resume-content.sent-list-table.filter-subscription"),
              campaignSendingValue: {key: 'subscriptions', values: this.selectedSubscriptions}
            },
            {
              iconLabel: this.$t("views.marketing.report.resume-content.sent-list-table.filter-group"),
              campaignSendingValue: {key: 'groups', values: this.selectedGroups}
            }
          ];
          this.items = [
            {
              iconLabel: this.$t("views.marketing.report.resume-content.sent-list-table.filter-recipients"),
              campaignSendingValue: {key: 'customers', values: this.selectedCustomers}
            }
          ];
          this.items = this.emailCampaign.filters ? [...this.items, ...filters] : this.items;
        })
  }
}
</script>
